import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';


const container = document.getElementById("list-articles");

if (container) {
  ReactDOM.render(<App />, container);
}

