import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';


export const ArticleContext = createContext();


export const ArticleProvider = (props) => {
    const categories = window?.listArticles?.articleCategory;
    const [loading, isLoading] = useState(false);
    const [articles, setArticles] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const URL = `/wp-json/wp/v2/posts?_embed&per_page=100&categories=${categories !== null ? categories : ""}`;

    useEffect(async () => {
        try {
            isLoading(true)
            const { data } = await axios.get(URL);
            setArticles(data)
            setSearchResults(data)
        } catch (error) {
            console.log(error)
        }
        finally {
            isLoading(false)
        }
    }, [])


    return (
        <ArticleContext.Provider value={{
            articles: [articles, setArticles],
            searchTerm: [searchTerm, setSearchTerm],
            searchResults: [searchResults, setSearchResults],
            loading: [loading, isLoading]
        }}>
            {props.children}
        </ArticleContext.Provider>
    );
}

export default ArticleContext;