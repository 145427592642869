import { ArticleProvider } from "./ArticleContext";
import Cards from "./components/Cards/Cards";
import { Search } from "./components/Search/Search";
import Aos from 'aos';
import 'aos/dist/aos.css';

function App() {
  Aos.init();
  return (
    <ArticleProvider>
      <Search />
      <Cards />
    </ArticleProvider>
  );
}

export default App;
