
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';


export const useStyles = makeStyles({
    '@global': {
        '.MuiAutocomplete-option': {
            fontFamily: 'Fira Sans, sans-serif',
        },
    }
});

export const SearchInput = withStyles({
    root: {
        maxWidth: '300px',
        border: '1px solid #CCCCCC !important',
        backgroundColor: '#FFF',
        borderRadius: '50px',
        marginBottom: '20px',

        '& label': {
            fontFamily: 'Fira Sans, sans-serif !important',
            color: '#7A7A7A',
            fontSize: '14px',
        },

        '& label.Mui-focused': {
            color: '#7A7A7A',
            marginLeft: '0px!important',
        },
        '& .MuiAutocomplete-popupIndicator': {
            padding: "6px",
            marginRight: "10px",
            '& .MuiSvgIcon-root': {
                fontSize: "0.6em",
                color: '#7a7a7a',

                "& path": {
                    d: "path('M2.82 4.58984L12 13.7498L21.18 4.58984L24 7.40984L12 19.4098L0 7.40984L2.82 4.58984Z')", // your svg icon path here
                }
            },
        },

        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(0px, -16px) scale(0.75)!important',
        },
        '& .MuiAutocomplete-option': {
            fontFamily: 'Fira Sans, sans-serif !important',
        },
        '& .MuiAutocomplete-popupIndicatorOpen': {
            transform: "rotate(180deg)!important",
        },

        '& label.MuiInputLabel-outlined': {
            backgroundColor: 'transparent !important',
            padding: '0 18px !important',
        },
        '& input': {
            padding: "14px 12px 12px 12px!important",
            fontWeight: 'bold !important',
            fontSize: '21px !important',
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: '#CCCCCC',
            borderBottom: '1px solid #CCCCCC',
        },
        '& .MuiOutlinedInput-root': {
            border: '0',
            padding: '0px !important',
            paddingLeft: '16px !important',
            '& fieldset': {
                border: 0,
            },
            '&:hover fieldset': {
                border: 0,
            },
        },
    },
})(TextField);
