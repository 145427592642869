import React, { useContext } from 'react';
import { ArticleContext } from '../../ArticleContext';

function Cards() {
	const { searchResults, loading, categoryColor } = useContext(ArticleContext);
	const [ valueSearchResults, valueSetSearchResults ] = searchResults;
	const [ loadingResults, setLoadingResults ] = loading;
	const color = categoryColor;

	return (
		<div className="cards">
			<div className="cards__container">
				{loadingResults ? (
					<p className="card__loading">Ladataan tuloksia...</p>
				) : (
					valueSearchResults
						.sort(function(a, b) {
							if (
								a._embedded['wp:term'][0][0].name.toLowerCase() <
									b._embedded['wp:term'][0][0].name.toLowerCase() ||
								a.title.rendered < b.title.rendered
							)
								return -1;
							if (
								a._embedded['wp:term'][0][0].name.toLowerCase() >
									b._embedded['wp:term'][0][0].name.toLowerCase() ||
								a.title.rendered > b.title.rendered
							)
								return 1;
							return 0;
						})
						.map((article) => (
							<div className="card__single" data-aos="fade-up" key={article.id}>
								<a href={article.link}>
									<div
										className="card__header"
										style={{
											backgroundColor: `${article.category_color}`,
											backgroundImage: article._embedded['wp:featuredmedia']
												? `url(${article._embedded['wp:featuredmedia']['0'].source_url})`
												: 'none'
										}}
									/>
									<div
										className="card__category-color"
										style={{ backgroundColor: `${article.category_color}` }}
									/>
									<div className="card__content">
										<span className="card__category">
											{article._embedded['wp:term'][0][0].name}
										</span>
										<h3 className="card__title">
											{article.title.rendered}
										</h3>
										<a href={article.link} className="card__button">
											<button className="button ghost">{article._embedded['wp:term'][0][0].name === 'Haastattelut' || 'Julkaisut' ? 'Lue lisää' : 'Katso ohje'}</button>
										</a>
									</div>
								</a>
							</div>
						))
				)}
			</div>
		</div>
	);
}

export default Cards;
