import React, { useContext, useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ArticleContext } from '../../ArticleContext';
import { useStyles } from './SearchStyles';

import {
    SearchInput,
} from './SearchStyles';

export const Search = () => {
    useStyles();

    const { articles, searchTerm, searchResults } = useContext(ArticleContext)
    // Default data
    const [articlesValues, setArticlesValues] = articles;

    // Manipulated data
    const [res, setRes] = searchResults;

    // On select term and typed value
    const [searchTermValue, setSearchTermValue] = searchTerm;
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (searchTermValue === null) setRes(articlesValues)
        else {
            const results = articlesValues.filter(articlesValues =>
                articlesValues.title.rendered.toLowerCase().includes(searchTermValue.title.rendered.toLowerCase())
            );

            setRes(results)
        }
    }, [searchTermValue])


    useEffect(() => {
        if (inputValue === null) setRes(articlesValues)
        const results = articlesValues.filter(articlesValues =>
            articlesValues.title.rendered.toLowerCase().includes(inputValue.toLowerCase())
        );
        setRes(results)
    }, [inputValue])

    return (
        <Autocomplete

            id="combo-box-demo"
            options={articlesValues}
            onChange={(event, value) => {
                setSearchTermValue(value);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            noOptionsText={'Tuloksia ei löytynyt'}
            getOptionLabel={(option) => option.title.rendered}
            renderInput={(params) => <SearchInput {...params} label="Etsi ohjetta hakusanalla" variant="outlined" />
            }

        />
    );


}
